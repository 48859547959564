import React from "react";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import { connect } from "react-redux";
import "../../_metronic/_assets/sass/app_tab.scss";
import Opportunities from "../opportunities";
import Admin from "../opportunities/Admin"; // AdminSection for opportunity.
import FromElements from "./";
import Auth from "../module/Auth"; // Credentials section for workforce, contact, prospect.
import Docs from "../docs";
import UserEmails from "../campaigns/UserEmails";
import Notes from "../services/notes";
import Payments from "../payments";
import Tasks from "../services/tasks";

/**
 * Date : 10-06-2023
 * Task : Market Place
 * Company: FinLend, LLC
 * Requested By : Rajesh
 */
import MarketPlace from "../opportunities/marketplace";
//import History from "../services/history";

const LsTabs = (props) => {
  let MI = { ...props.module };
  let prospectControl = true;
  if (
    props &&
    props.user &&
    props.user.company._id.toString() === "61014b8959c5142ac2ab2180" &&
    props.user.type === "Prospects"
  ) {
    prospectControl = false;
  }
  const getFields = (config) => {
    let fields = [];
    Array.isArray(config) &&
      config.forEach((c) => {
        if (
          c.type !== "container" &&
          c.type !== "grid" &&
          c.type !== "item" &&
          c.type !== "tab" &&
          c.type !== "section" &&
          c.type !== "file"
        ) {
          if (MI.requiredFields.includes(c.id)) {
            fields = [...fields, c];
          }
        }
        if (c.childs) fields = [...fields, ...getFields(c.childs)];
      });
    return fields;
  };

  const getTabs = (config) => {
    let tabs = [];
    Array.isArray(config) &&
      config.forEach((c) => {
        if (c.type === "item") {
          let fields = [];
          fields = getFields(c.childs);
          if (fields.length > 0) {
            tabs = [...tabs, c.id];
          }
        }
        if (c.childs) tabs = [...tabs, ...getTabs(c.childs)];
      });
    return tabs;
  };

  // tabs controlled by roles
  let tab_list = [];
  let tab_ids = [];
  props.element.childs.forEach((t) => {
    let allow = true;

    if (t.hided_roles && t.hided_roles.length > 0) {
      let troles = [];
      t.hided_roles.forEach((r) => {
        troles = [...troles, r._id];
      });
      if ((props.user && troles.includes(props.user.role._id)) || !props.user) {
        allow = false;
      }
    }

    if (allow) {
      tab_ids = [...tab_ids, t.id];
      tab_list = [...tab_list, t];
    }
  });
  tab_ids = [...tab_ids, ...MI.allowed_tabs];

  let required_tabs = getTabs(MI.form.content);
  let tabStyle = "";
  required_tabs.forEach((rt) => {
    tabStyle += `
      .tabs nav a[data-rb-event-key="${rt}"] {
        border: 2px solid red;
      }
      .tabs-style-bar nav a[data-rb-event-key="${rt}"]:hover {
        border: 2px solid red;
      } 
    `;
  });

  const onTabChangeHandler = (key, e) => {
    e.preventDefault();
    var myDiv = document.getElementById("kt_content");
    myDiv.scrollTop = 0;
    let from = "next";

    // Webform tab validation except back tabs
    if (!props.user) {
      let current_tab = 0;
      let next_tab = key === "docs" ? tab_list.length + 1 : 0;
      tab_list.forEach((t, i) => {
        if (MI.form.activeTab === t.id) current_tab = i;
        if (key === t.id) next_tab = i;
      });
      if (next_tab < current_tab) from = "back";
    }
    props.events.tabChange(key, from);
  };

  return (
    <>
      <style type="text/css">{tabStyle}</style>
      <input type="hidden" id="first_tab" value={tab_ids[0]} />
      <input type="hidden" id="active_tab" value={MI.form.activeTab} />
      <input type="hidden" id="last_tab" value={tab_ids[tab_ids.length - 1]} />
      <div className="tabs tabs-style-bar col-md-12 mb-3">
        <Tab.Container
          className="custom-nav"
          activeKey={MI.form.activeTab || tab_list[0].id}
          onSelect={onTabChangeHandler}
        >
          <Row>
            <Col md={2}>
              <Nav variant="pills" className="flex-column luxury-vertical-nav">
                {tab_list.map((child, i) => (
                  <Nav.Item
                    key={i}
                    style={
                      required_tabs.includes(child.id)
                        ? { border: "2px solid red" }
                        : {}
                    }
                  >
                    <Nav.Link eventKey={child.id}>{child.title}</Nav.Link>
                  </Nav.Item>
                ))}

                {tab_ids.includes("admin") &&
                  props.user.company._id.toString() ===
                    MI.opportunity.company.toString() && (
                    <Nav.Item>
                      <Nav.Link eventKey="admin">Admin Info</Nav.Link>
                    </Nav.Item>
                  )}

                {tab_ids.includes("auth") && (
                  <Nav.Item>
                    <Nav.Link eventKey="auth">Auth Information</Nav.Link>
                  </Nav.Item>
                )}

                {tab_ids.includes("opportunities") && (
                  <Nav.Item>
                    <Nav.Link eventKey="opportunities">Loans</Nav.Link>
                  </Nav.Item>
                )}

                {tab_ids.includes("docs") && (
                  <Nav.Item>
                    <Nav.Link eventKey="docs">
                      {props.user.company._id.toString() ===
                      "61014b8959c5142ac2ab2180"
                        ? "Document Library"
                        : "Docs Wizard"}
                    </Nav.Link>
                  </Nav.Item>
                )}
                
                {tab_ids.includes("emails") && (
                  <Nav.Item>
                    <Nav.Link eventKey="emails">Emails</Nav.Link>
                  </Nav.Item>
                )}

                {!props.user?.company?.products?.includes(
                  "65605b114f5a571d25918257"
                ) &&
                  props &&
                  props.module.form.company &&
                  (props.module.form.company._id
                    ? props.module.form.company._id !==
                      "639bf20fb276620c63063031"
                    : props.module.form.company !==
                      "639bf20fb276620c63063031") &&
                  prospectControl &&
                  tab_ids.includes("tasks") && (
                    <Nav.Item>
                      <Nav.Link eventKey="tasks">Tasks</Nav.Link>
                    </Nav.Item>
                  )}
                {prospectControl && tab_ids.includes("notes") && (
                  <Nav.Item>
                    <Nav.Link eventKey="notes">Notes</Nav.Link>
                  </Nav.Item>
                )}

                {tab_ids.includes("payments") && (
                  <Nav.Item>
                    <Nav.Link eventKey="payments">Payments</Nav.Link>
                  </Nav.Item>
                )}

                {tab_ids.includes("marketplace") && (
                  <Nav.Item>
                    <Nav.Link eventKey="marketplace">Lenders</Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
            </Col>
            <Col md={10}>
              <Tab.Content>
                {tab_list.map((child, i) => (
                  <Tab.Pane
                    eventKey={child.id}
                    title={
                      child.title.toLowerCase() === child.id
                        ? "Tab - " + (i + 1)
                        : child.title
                    }
                    key={i}
                  >
                    {child.childs.length &&
                      child.childs.map((content, j) => (
                        <div className="col-md-12" key={j}>
                          <FromElements
                            element={content}
                            module={MI}
                            events={props.events}
                          />
                          <br />
                        </div>
                      ))}
                  </Tab.Pane>
                ))}
                {tab_ids.includes("admin") &&
                  props.user.company._id.toString() ===
                    MI.opportunity.company.toString() && (
                    <Tab.Pane eventKey="admin" title="Admin Info">
                      <Admin module={MI} actions={props.events} />
                    </Tab.Pane>
                  )}

                {!props.user?.company?.products?.includes(
                  "65605b114f5a571d25918257"
                ) &&
                  props &&
                  props.module.form.company &&
                  (props.module.form.company._id
                    ? props.module.form.company._id !==
                      "639bf20fb276620c63063031"
                    : props.module.form.company !==
                      "639bf20fb276620c63063031") &&
                  tab_ids.includes("auth") && (
                    <Tab.Pane eventKey="auth" title="Auth Information">
                      <Auth module={MI} events={props.events} />
                    </Tab.Pane>
                  )}

                {tab_ids.includes("opportunities") && (
                  <Tab.Pane eventKey="opportunities" title="Loans">
                    {MI.form.activeTab === "opportunities" && <Opportunities />}
                  </Tab.Pane>
                )}

                {!props.user?.company?.products?.includes(
                  "65605b114f5a571d25918257"
                ) &&
                  tab_ids.includes("docs") && (
                    <Tab.Pane eventKey="docs" title="Docs Wizard">
                      {MI.form.activeTab === "docs" && (
                        <Docs module={MI} events={props.events} />
                      )}
                    </Tab.Pane>
                  )}

                {tab_ids.includes("emails") && MI.from !== "leads" && (
                  <Tab.Pane eventKey="emails" title="Emails">
                    {MI.form.activeTab === "emails" && (
                      <UserEmails
                        module={MI.form._id}
                        owner={MI.MDID}
                        email={MI.dynamicFieldData.email}
                        opp={MI.opportunity ? MI.opportunity : {}}
                        MI={MI}
                      />
                    )}
                  </Tab.Pane>
                )}

                {!props.user?.company?.products?.includes(
                  "65605b114f5a571d25918257"
                ) &&
                  props &&
                  props.module.form.company &&
                  (props.module.form.company._id
                    ? props.module.form.company._id !==
                      "639bf20fb276620c63063031"
                    : props.module.form.company !==
                      "639bf20fb276620c63063031") &&
                  tab_ids.includes("tasks") && (
                    <Tab.Pane eventKey="tasks" title="Tasks">
                      {MI.form.activeTab === "tasks" && (
                        <Tasks
                          info={MI}
                          permissions={MI.permissions}
                          module={MI.form._id}
                          owner={MI.MDID}
                        />
                      )}
                    </Tab.Pane>
                  )}

                {tab_ids.includes("notes") && (
                  <Tab.Pane eventKey="notes" title="Notes">
                    {MI.form.activeTab === "notes" && (
                      <Notes
                        module={
                          MI.from === "opportunities"
                            ? "opportunities"
                            : MI.from === "leads"
                            ? "leads"
                            : MI.form._id
                        }
                        permissions={MI.permissions}
                        owner={MI.MDID}
                        from="tabs"
                        MI={MI}
                      />
                    )}
                  </Tab.Pane>
                )}

                {tab_ids.includes("payments") && (
                  <Tab.Pane eventKey="payments" title="Payments">
                    {MI.form.activeTab === "payments" && (
                      <Payments
                        module={
                          MI.from === "opportunities"
                            ? "opportunities"
                            : MI.from === "leads"
                            ? "leads"
                            : MI.form._id
                        }
                        permissions={MI.permissions}
                        owner={MI.MDID}
                        from="tabs"
                        opportunity={MI.opportunity}
                        company={MI.form.company}
                      />
                    )}
                  </Tab.Pane>
                )}

                {tab_ids.includes("marketplace") && (
                  <Tab.Pane eventKey="marketplace" title="Lenders">
                    {MI.form.activeTab === "marketplace" && (
                      <MarketPlace info={MI} events={props.events} />
                    )}
                  </Tab.Pane>
                )}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>

        {/* <Tabs
          activeKey={MI.form.activeTab}
          unmountOnExit={false}
          onSelect={onTabChangeHandler}
          id="content-tab"
        >
          {tab_list.map((child, i) => (
            <Tab
              eventKey={child.id}
              title={
                child.title.toLowerCase() === child.id
                  ? "Tab - " + (i + 1)
                  : child.title
              }
              key={i}
            >
              {child.childs.length &&
                child.childs.map((content, j) => (
                  <div className="col-md-12" key={j}>
                    <FromElements
                      element={content}
                      module={MI}
                      events={props.events}
                    />
                    <br />
                  </div>
                ))}
            </Tab>
          ))}

          {tab_ids.includes("admin") && (
            <Tab eventKey="admin" title="Admin Info">
              <Admin module={MI} actions={props.events} />
            </Tab>
          )}
          {props &&
            props.module.form.company &&
            (props.module.form.company._id
              ? props.module.form.company._id !== "639bf20fb276620c63063031"
              : props.module.form.company !== "639bf20fb276620c63063031") &&
            tab_ids.includes("auth") && (
              <Tab eventKey="auth" title="Auth Information">
                <Auth module={MI} events={props.events} />
              </Tab>
            )}

          {tab_ids.includes("opportunities") && (
            <Tab eventKey="opportunities" title="Loans">
              {MI.form.activeTab === "opportunities" && <Opportunities />}
            </Tab>
          )}

          {tab_ids.includes("docs") && (
            <Tab eventKey="docs" title="Docs Wizard">
              {MI.form.activeTab === "docs" && (
                <Docs module={MI} events={props.events} />
              )}
            </Tab>
          )}

          {tab_ids.includes("emails") && (
            <Tab eventKey="emails" title="Emails">
              {MI.form.activeTab === "emails" && (
                <UserEmails
                  module={MI.form._id}
                  owner={MI.MDID}
                  email={MI.dynamicFieldData.email}
                  opp={MI.opportunity ? MI.opportunity : {}}
                  MI={MI}
                />
              )}
            </Tab>
          )}
          {tab_ids.includes("notes") && (
            <Tab eventKey="notes" title="Notes">
              {MI.form.activeTab === "notes" && (
                <Notes
                  module={
                    MI.from === "opportunities"
                      ? "opportunities"
                      : MI.from === "leads"
                      ? "leads"
                      : MI.form._id
                  }
                  permissions={MI.permissions}
                  owner={MI.MDID}
                  from="tabs"
                  MI={MI}
                />
              )}
            </Tab>
          )}

          {tab_ids.includes("payments") && (
            <Tab eventKey="payments" title="Payments">
              {MI.form.activeTab === "payments" && (
                <Payments
                  module={
                    MI.from === "opportunities"
                      ? "opportunities"
                      : MI.from === "leads"
                      ? "leads"
                      : MI.form._id
                  }
                  permissions={MI.permissions}
                  owner={MI.MDID}
                  from="tabs"
                  opportunity={MI.opportunity}
                  company={MI.form.company}
                />
              )}
            </Tab>
          )}
        </Tabs> */}
      </div>
    </>
  );
};

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(LsTabs);
